import React from 'react';
import { Parallax } from "react-parallax";
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const image1 = "./img/background/l7.jpg";

const Mblockquote = () => {
    return (
        <div className="section bg-top bg-bottom py-0">
            <Parallax className="py-5" bgImage={image1} strength={300}>
                <div className="py-5 position-relative">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer"
                                        data-aos="fade"
                                        data-aos-delay="0"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease"
                                        data-aos-once="true"
                                    >
                                        <img src="https://hopetutors.com/wp-content/uploads/2017/08/mern-stack-training.png" alt="MERN Stack" style={{ width: '234px', height: '160px' }} />
                                    </h3>
                                    <span className="small" style={{ fontWeight: 'bold' }}>MERN Stack</span>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer"
                                        data-aos="fade"
                                        data-aos-delay="0"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease"
                                        data-aos-once="true"
                                    >
                                        <img src="https://blog.logrocket.com/wp-content/uploads/2021/06/flutterfire-plugins-firebase-flutter-app.png" alt="Flutter" style={{ width: '240px', height: 'auto' }} />
                                    </h3>
                                    <span className="small" style={{ fontWeight: 'bold' }}>Flutter | Firebase</span>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer"
                                        data-aos="fade"
                                        data-aos-delay="0"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease"
                                        data-aos-once="true"
                                    >
                                        <img src="https://i.pinimg.com/736x/fb/07/8f/fb078f46c0882ee08de357c58a1ac883--android-sdk-tablet-android.jpg" alt="Android" style={{ width: '160px', height: '160px' }} />
                                    </h3>
                                    <span className="small" style={{ fontWeight: 'bold' }}>Android Studio</span>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer"
                                        data-aos="fade"
                                        data-aos-delay="0"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease"
                                        data-aos-once="true"
                                    >
                                        <img src="https://th.bing.com/th/id/OIP.mI6iW5glXCTrnzYQuOQlfAHaFj?w=1024&h=768&rs=1&pid=ImgDetMain" alt="html_css_js" style={{ width: '240px', height: '162px' }} />
                                    </h3>
                                    <span className="small" style={{ fontWeight: 'bold' }}>HTML | JS | CSS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    );
}

export default Mblockquote;
