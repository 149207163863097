import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox3 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox4 */
    const [lighbx3, setlighbx3] = React.useState(false);
    const handleBtnClick3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
      /* lightbox5 */
      const [lighbx4, setlighbx4] = React.useState(false);
      const handleBtnClick4 = (): void => {
        setlighbx4(!lighbx4);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
      };
      const handleBtnClickclose4 = (): void => {
        setlighbx4(!lighbx4);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
      };
       /* lightbox6 */
       const [lighbx5, setlighbx5] = React.useState(false);
       const handleBtnClick5 = (): void => {
         setlighbx5(!lighbx5);
         var x = document.getElementsByTagName("BODY")[0];
         x.style.overflow = "hidden";
       };
       const handleBtnClickclose5 = (): void => {
         setlighbx5(!lighbx5);
         var x = document.getElementsByTagName("BODY")[0];
         x.style.overflow = "auto";
       };
    return(
            <div className="container">
            <GlobalStyles/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Projects</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>
                    <div className="col-lg-4 image-element-class de_modal de_modal" onClick={handleBtnClick}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                            <h3></h3>
                                <h5 className="d-tag">Chatbee</h5>
                            </div>
                            <img src="./img/gallery/1.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        > 
                        <div className="card-image-1">
                            <div className="d-text">
                            <h5 className="d-tag">FruityBloom</h5>
                            </div>
                            <img src="./img/gallery/2.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                
                                <h5 className="d-tag">NexNest</h5>
                            </div>
                            <img src="./img/gallery/3.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick5}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3></h3>
                                <h5 className="d-tag">CV Matching Platform</h5>
                            </div>
                            <img src="./img/gallery/5.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick3}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3></h3>
                                <h5 className="d-tag">My To-Do Application</h5>
                            </div>
                            <img src="./img/gallery/4.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick4}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3></h3>
                                <h5 className="d-tag">Bloom Valentina</h5>
                            </div>
                            <img src="./img/gallery/6.png" alt="gallery"/>
                        </div>
                    </div>
                    
                  </Masonry>

                  {/* lightbox1 */}
                  {lighbx && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                            <video className="pop" controls autoPlay loop>
                                  <source src="./video/chatbot.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>My Intelligent Chat Assistant 🤖</h3>
                                <p style={{ textAlign: 'justify' }}>
                               ChatBee is an innovative project I've developed, featuring an intelligent chat assistant powered by state-of-the-art technology. Leveraging transformers from the microsoft/DialoGPT-medium model, ChatBee employs advanced natural language processing techniques to provide seamless and engaging conversations. With a sleek design and intuitive interface, ChatBee offers personalized responses to user queries, ranging from weather updates and news retrieval to entertainment options like music and videos. Continuously learning and adapting, ChatBee ensures each interaction is tailored to user preferences, making it the ultimate virtual companion. Connect with me to explore the world of ChatBee and its capabilities.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares:  <span>Python,Html,CSS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2024</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://github.com/SanduniManjula/Chatbot" target="_blank" rel="noreferrer">www.chatbee.com</a></span>
                                    </div>
                                </div>
                               
                               
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  {/* lightbox2 */}
                  {lighbx1 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                            <video className="pop" controls autoPlay loop>
                                  <source src="./video/Fruits store.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Online Fruits Store Platform.....  Fruity Bloom 🤗</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    I designed a fruit store web app using React, Node.js, and MongoDB. After Figma UI design, built frontend and backend in VSCode, implementing user authentication. Customers and suppliers login separately. Suppliers access a dashboard for managing details, while customers explore home and cart pages. Customers cannot go to the dashboard. because its only for admins. Admin-exclusive dashboard prevents customer access. MongoDB with Mongoose handles all database operations seamlessly. Users log in with name and password, enabling item search, details viewing, and cart management. Suppliers can add, edit, or remove details on the dashboard. Customer transactions, including adding details and payment, are streamlined. The web app offers a comprehensive, user-friendly fruit shopping experience.</p>
                               
                                <div className="de_project-details">
                                   
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares: <span>React, Node.js, Mongodb</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://github.com/SanduniManjula/Online_Fruits_Store_platform" target="_blank" rel="noreferrer">www.fruitybloom.com</a></span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* lightbox3 */}
                {lighbx2 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./video/Mobile 2.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Rentals & Landlords Platform</h3>
                                <p style={{ textAlign: 'justify' }}> 🏡 Introducing NexNest, the premier rentals and landlords platform in Sri Lanka! Crafted with expertise and passion, the NexNest app revolutionizes property seeking and management. Seamlessly developed using Flutter, Firebase ensures secure login/signup and data management via Firestore.</p>

                                <p style={{ textAlign: 'justify' }}> 🛠️ Navigate effortlessly through personalized features: from the intuitive Home Screen to the Location Wizard, SearchWhenToGoPage, and SearchWhoToGoScreen, every aspect is designed for user convenience. Discover properties with ease on the SearchResultsScreen and delve into comprehensive details on the HouseDetailsOverviewScreen.</p>

                                <p style={{ textAlign: 'justify' }}> 💳  Facilitating smooth transactions, the CheckoutScreen simplifies payments, while the UserProfileScreen and EditProfileScreen offer quick access and seamless modification of user details. With the AddAPlaceScreen and EditAPlaceScreen, landlords can effortlessly input rental details, upload photos, and customize options for an enhanced listing experience.</p>

                                <p style={{ textAlign: 'justify' }}> 🏠 NexNest redefines the rental marketplace, merging cutting-edge technology with user-centric design to elevate the renting experience. Welcome to your new home-finding companion, where every detail is crafted for your convenience and satisfaction.</p>

                               
                                <div className="de_project-details">  
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares: <span>Dart, Flutter, Firebase</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.linkedin.com/posts/sanduni-manjula-427169260_nexnest-innovationinrentals-mobileapprevolution-activity-7154473111101313024-_0BU?utm_source=share&utm_medium=member_desktop" target="_blank" rel="noreferrer">www.nexnest.com</a></span>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                   {/* lightbox4 */}
                   {lighbx3 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose3}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                            <video className="pop" controls autoPlay loop>
                                  <source src="./video/todo.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>To Do Application 👩‍💻  </h3>
                                <p style={{ textAlign: 'justify' }}>
                                I developed this to do app using Java and Android Studio, this feature-rich todo app empowers users to seamlessly manage tasks. With functionalities like add, delete, update, and reset, users can organize their agenda effortlessly. The app also allows users ensuring a personalized touch for tracking and prioritizing special tasks.</p>
                                <div className="de_project-details">
                                   
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares: <span>Android Studio, Java</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://github.com/SanduniManjula/MyToDoApplication" target="_blank" rel="noreferrer">www.todo.com</a></span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                  {/* lightbox5 */}
                  {lighbx4 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose4}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                            <video className="pop" controls autoPlay loop>
                                  <source src="./video/bloom valentina.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>💐Bloom Valentina💐 </h3>
                                <p style={{ textAlign: 'justify' }}>
                                A charming flower website, personally crafted for hassle-free Valentine's Day shopping. Created with simple functions using React and JavaScript, it's a delightful experience designed for pure enjoyment during leisure hours. Explore and effortlessly spread love with Bloom Valentina.</p>
                                <div className="de_project-details">
                                   
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares: <span>React, JavaScript, CSS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://github.com/SanduniManjula/Bloom-Valentina" target="_blank" rel="noreferrer">www.bloomvalentina.com</a></span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                   

                {/* lightbox6 */}
                  {lighbx5 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose5}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery-big/1.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery-big/2.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery-big/3.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery-big/5.jpg"alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery-big/7.jpg"alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery-big/6.jpg"alt="galleryimage" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>CV Matching Platform</h3>
                                <p style={{ textAlign: 'justify' }}> Our current project, CV matching platform is designing to revolutionize the job search process by seamlessly connecting job seekers and companies based on qualifications. With a user-friendly interface, individuals can easily create and manage their profiles, showcasing their skills, work experience, and education. Job seekers have the flexibility to update or delete their profiles and upload their CVs, providing comprehensive information to potential employers.</p>
                                <p style={{ textAlign: 'justify' }}> On the other hand, companies can create detailed profiles outlining their requirements and preferences for potential candidates. Leveraging advanced scanning technology, our platform allows companies to analyze uploaded CVs and user profile details, streamlining the process of identifying suitable candidates. By integrating machine learning algorithms, the platform intelligently matches job seekers with relevant job openings based on qualifications, ensuring a more efficient and targeted job search experience.</p>
                                <p style={{ textAlign: 'justify' }}> For job seekers, the platform offers a tailored approach to finding employment opportunities that align with their qualifications and career aspirations. They can browse through job listings curated specifically for them, narrowing down their search based on factors such as industry, location, and job type. With our CV matching platform, job seekers can confidently pursue their career goals, knowing they are being connected with companies that value their skills and experiences, ultimately facilitating successful job placements. </p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>NS Business & Consultancy Services (Pvt) Ltd</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Used Softwares: <span>MERN, Javascript, SCSS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2023 - Present</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://github.com/Nipunikumudika/CV-Matching-Website" target="_blank" rel="noreferrer">www.cvmatching.com</a></span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
      </div>
    );
}

export default Gallery;