import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { settings } from './settingsnew';
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();


const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const Blog= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox3 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox4 */
    const [lighbx3, setlighbx3] = React.useState(false);
    const handleBtnClick3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

	return(
		<div className="container">
            <GlobalStyles/>
            <div className="spacer-single"></div>
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>Recent Blog</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row"
                data-aos="fade-up"
                data-aos-once="true"
                >
				<Slider {...settings}>
                <CustomSlide className='itm' index={1}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/4.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>The Evolution of Chatbots</h4>
                                <p style={{ textAlign: 'justify' }}>  As technology continues to evolve at a rapid pace, the future of chatbots holds boundless possibilities for revolutionizing digital...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={2}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick1}>
                                  <img alt="blogimg" src="./img/blog/2.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick1}>The Future of CV Matching Platforms </h4>
                                <p style={{ textAlign: 'justify' }}> In today's fast-paced job market, the traditional approach to job hunting can often feel outdated and inefficient. However, with the...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={3}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick2}>
                                  <img alt="blogimg" src="./img/blog/5.jpg" className="grayscale" style={{ width: 'auto', height: '254px' }} />
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick2}>Tomorrow's Booking Experience</h4>
                                <p style={{ textAlign: 'justify' }}> Online booking platforms have become the cornerstone of modern convenience, revolutionizing how we plan and experience everything...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={4}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick3}>
                                  <img alt="blogimg" src="./img/blog/6.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text" onClick={handleBtnClick3}>
                                <h4 className="de_modal">Global Linguistic Connectivity</h4>
                               
                                <p style={{ textAlign: 'justify' }}> In the realm of language identification systems, the integration of advanced machine learning algorithms like Naive Bayes and K-Nearest Neighbors...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

              </Slider>
			</div>

			  {/* lightbox1 */}
	          {lighbx && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog/4.jpg" className="img-fullwidth rounded"/>

                                
                                <h2>Revolutionizing Digital Interactions</h2>

                                <div className="post-text">
                                <p style={{ textAlign: 'justify' }}>As technology continues to evolve at a rapid pace, the future of chatbots holds boundless possibilities for revolutionizing digital interactions. These intelligent virtual assistants are set to become even more integral to our daily lives, offering seamless and personalized experiences across various platforms. Powered by advancements in artificial intelligence and natural language processing, future chatbots will possess unprecedented levels of sophistication, enabling them to understand and respond to human queries with remarkable accuracy and nuance.</p>

                                <p style={{ textAlign: 'justify' }}>One key aspect of the future of chatbots lies in their ability to seamlessly integrate with other emerging technologies, such as augmented reality (AR) and virtual reality (VR). Imagine chatting with a virtual assistant while wearing AR glasses, receiving real-time information and assistance as you navigate the physical world. Similarly, in VR environments, chatbots could serve as invaluable guides, providing users with immersive and interactive experiences tailored to their preferences and interests. By leveraging these technologies, chatbots will not only enhance productivity but also offer new avenues for entertainment and education.</p>

                                <p style={{ textAlign: 'justify' }}>Furthermore, the future of chatbots is likely to be characterized by increased personalization and emotional intelligence. These next-generation virtual assistants will be adept at understanding not only what users say but also how they feel, enabling them to tailor responses and recommendations accordingly. Through continuous learning and adaptation, chatbots will develop deeper relationships with users, serving as trusted companions that anticipate and fulfill their needs in ways that feel natural and intuitive. As chatbots evolve to become more empathetic and responsive, they will redefine the way we interact with technology, ushering in a new era of human-computer interaction.</p>

                               
                                </div>

                            </div>

                            <div id="blog-comment">
                             

                                

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" className="form-control"/>
                                            <label>Email <span className="req">*</span></label>
                                            <input type="text" name="email" id="email" className="form-control"/>
                                            <label>Message <span className="req">*</span></label>
                                            <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                            </textarea>
                                            <p id="btnsubmit">
                                            <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )}
              {/* lightbox2 */}
	          {lighbx1 && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose1}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog/2.jpg" className="img-fullwidth rounded"/>

                            
                                <h2>The Future of CV Matching Platforms</h2>

                                <div className="post-text">
                                <p style={{ textAlign: 'justify' }}>In today's fast-paced job market, the traditional approach to job hunting can often feel outdated and inefficient. However, with the advent of advanced technology and machine learning, a new era of CV matching platforms is emerging, promising to streamline the job search process for both job seekers and companies alike.</p>

                                <p style={{ textAlign: 'justify' }}>Imagine a platform where job seekers can effortlessly create and manage their profiles, showcasing their qualifications and experiences in a comprehensive manner. From uploading CVs to specifying job preferences, this platform empowers individuals to present themselves effectively to potential employers, all within a user-friendly interface designed for seamless navigation.</p>

                                <p style={{ textAlign: 'justify' }}>But the innovation doesn't stop there. Companies too can benefit from this cutting-edge technology by creating detailed profiles outlining their specific requirements and preferences. Leveraging sophisticated scanning algorithms, these platforms enable companies to sift through countless CVs and user profiles with ease, identifying the most suitable candidates in a fraction of the time it would traditionally take.</p>
                                <p style={{ textAlign: 'justify' }}>At the heart of these platforms lies the power of machine learning algorithms, which intelligently match job seekers with relevant job openings based on their qualifications and preferences. By analyzing vast amounts of data, these algorithms ensure that job seekers are connected with opportunities that align with their skills and career aspirations, ultimately leading to more successful job placements and happier professionals.</p>

                                <p style={{ textAlign: 'justify' }}>In essence, CV matching platforms represent the future of job searching, offering a dynamic and efficient solution to the age-old challenge of connecting talent with opportunity. As technology continues to evolve, these platforms will only become more sophisticated, revolutionizing the way we approach the job market and empowering individuals to pursue their career goals with confidence.</p>

                               
                                </div>

                            </div>

                            <div id="blog-comment">
                               

                                

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" className="form-control"/>
                                            <label>Email <span className="req">*</span></label>
                                            <input type="text" name="email" id="email" className="form-control"/>
                                            <label>Message <span className="req">*</span></label>
                                            <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                            </textarea>
                                            <p id="btnsubmit">
                                            <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )}
               {/* lightbox3 */}
	          {lighbx2 && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose2}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog/5.jpg" className="img-fullwidth rounded" />

                            

                                <h2>Tomorrow's Booking Experience</h2>

                                <div className="post-text">
                                <p style={{ textAlign: 'justify' }}>Online booking platforms have become the cornerstone of modern convenience, revolutionizing how we plan and experience everything from travel to dining and events. Looking ahead, the evolution of these platforms will be driven by innovative technologies that enhance user experiences, streamline operations, and offer unprecedented levels of personalization.</p>

                                <p style={{ textAlign: 'justify' }}>At the forefront of this evolution is Artificial Intelligence (AI) and Machine Learning (ML). These technologies are poised to revolutionize the online booking experience by analyzing vast amounts of data to predict user preferences, optimize search results, and personalize recommendations. AI-powered chatbots are already transforming customer service, providing instant assistance and resolving queries in real-time, enhancing user satisfaction and loyalty.</p>

                                <p style={{ textAlign: 'justify' }}>Furthermore, the advent of blockchain technology is set to revolutionize the online booking industry by addressing key pain points such as security, transparency, and trust. Blockchain enables secure, tamper-proof transactions, eliminating the need for intermediaries and reducing the risk of fraud. Smart contracts powered by blockchain ensure transparent agreements and automate processes, from booking confirmations to payment settlements, enhancing efficiency and reducing costs for both businesses and consumers.</p>
                                <p style={{ textAlign: 'justify' }}>Additionally, the Internet of Things (IoT) is reshaping the online booking landscape by creating interconnected ecosystems that offer seamless and personalized experiences. IoT-enabled devices, such as smart hotel rooms and connected vehicles, can anticipate and cater to individual preferences, from adjusting room temperatures to suggesting nearby attractions. As online booking platforms continue to evolve, integrating these cutting-edge technologies will be key to unlocking new levels of convenience, efficiency, and satisfaction for users worldwide.</p>
                               
                                </div>

                            </div>

                            <div id="blog-comment">
                                

                                

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" className="form-control"/>
                                            <label>Email <span className="req">*</span></label>
                                            <input type="text" name="email" id="email" className="form-control"/>
                                            <label>Message <span className="req">*</span></label>
                                            <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                            </textarea>
                                            <p id="btnsubmit">
                                            <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )}
               {/* lightbox4 */}
	          {lighbx3 && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose3}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog/6.jpg" className="img-fullwidth rounded" />

                            

                                <h2>Empowering Multilingual Communication</h2>

                                <div className="post-text">
                                <p style={{ textAlign: 'justify' }}>In the realm of language identification systems, the integration of advanced machine learning algorithms like Naive Bayes and K-Nearest Neighbors (KNN) heralds a new era of innovation and efficiency. Naive Bayes, known for its simplicity and effectiveness, utilizes probabilistic calculations based on word frequencies to classify texts into different languages. On the other hand, KNN leverages the similarities between texts to make predictions, making it adept at handling multilingual contexts.</p>

                                <p style={{ textAlign: 'justify' }}>The future of language identification systems lies in harnessing the power of these algorithms to achieve unprecedented levels of accuracy and adaptability. By employing Naive Bayes, these systems can swiftly analyze linguistic patterns and probabilities to determine the most likely language of a given text, even amidst complex and diverse linguistic landscapes. Meanwhile, KNN offers a complementary approach, leveraging the similarities between texts in a multilingual dataset to make informed predictions, thereby enhancing the system's versatility and robustness.</p>

                                <p style={{ textAlign: 'justify' }}>Moreover, as these algorithms continue to evolve alongside advancements in data processing and computational power, language identification systems are poised to become increasingly adept at recognizing subtle nuances and cultural references within texts. By combining the strengths of Naive Bayes and KNN, these systems can achieve a deeper understanding of language, enabling more accurate, efficient, and culturally sensitive communication across borders and languages. As we embrace the future of language identification, the fusion of these cutting-edge algorithms promises to unlock new possibilities for global communication and collaboration, transcending linguistic barriers and fostering greater connectivity and understanding in our interconnected world.</p>
                               
                                </div>

                            </div>

                            <div id="blog-comment">
                               

                                

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" className="form-control"/>
                                            <label>Email <span className="req">*</span></label>
                                            <input type="text" name="email" id="email" className="form-control"/>
                                            <label>Message <span className="req">*</span></label>
                                            <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                            </textarea>
                                            <p id="btnsubmit">
                                            <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )}

		</div>
	);
}

export default Blog;