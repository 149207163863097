import React from 'react';
import Skillbar from '../layout/skillbar';

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6">
                    <img src="./img/misc/5.png" className="img-fluid" alt=""/>
                </div>
	            <div className="col-md-6">
	                <h2>About Me</h2>
	                <p style={{ textAlign: 'justify' }} >As a Computer Engineering student, I excel in Python, Java, JavaScript, C#, and C++. Proficient in full-stack development, I'm eager for an internship to apply my skills, contribute to exciting projects, and continue learning. With a passion for innovation and a hunger for challenges, I aim to make a meaningful impact in the dynamic realm of technology.
	                </p>
	                <div className="d-skills-bar">
	                  <div className="d-bar">
	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>Java</span>
	                        </div>
	                        <Skillbar bgColor={"#CB7C6F"} progress={80} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>Java Script</span>
	                        </div>
	                        <Skillbar bgColor={"#CB7C6F"} progress={90} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>C++</span>
	                        </div>
	                        <Skillbar bgColor={"#CB7C6F"} progress={65} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>C#</span>
	                        </div>
	                        <Skillbar bgColor={"#CB7C6F"} progress={75} />
	                    </div>

	                    <div className="d-skill" data-value="90%" style={{width: "90%"}}>
	                        <div className="d-info">
	                          <span>Python</span>
	                        </div>
	                        <Skillbar bgColor={"#CB7C6F"} progress={70} />
	                    </div>

	                    </div>
	                </div>
	            </div>  

			</div>
		</div>
		</div>
	);
}

export default hero;