import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences & Awards</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2023</h3>
                                 <p style={{ textAlign: 'justify' }} className="d_timeline-text">
                                	<span className="d_title">1st Runner's Up | INSL 2023 Provincial Competition Southern Province</span>
                                	<span className="d_company">IEEE Innovation Nation Sri Lanka</span>
                                	Secured the runner-up position in the INSL 2023 Provincial Competition for the Southern Province, hosted by IEEE Innovation Nation Sri Lanka, showcasing innovation and excellence in the field of technology and engineering.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title"> 2023 </h3>
                                <p style={{ textAlign: 'justify' }}  className="d_timeline-text">
                                	<span className="d_title">5th Place & Best Innovation | Eminence 3.0 </span>
                                	<span className="d_company">IEEE Student Branch University of Ruhuna</span>
                                	Achieved 5th place and awarded Best Innovation at Eminence 3.0, an event hosted by the IEEE Student Branch at the University of Ruhuna, highlighting notable creativity and proficiency in technological innovation within the academic community.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2022</h3>
                                <p style={{ textAlign: 'justify' }}  className="d_timeline-text">
                                	<span className="d_title">1st Runner's Up | Spaghetti Bridge Competition </span>
                                	<span className="d_company">Civil & Environmental Engineering Society University of Ruhuna</span>Secured the runner-up position at the Spaghetti Bridge Competition organized by the Civil & Environmental Engineering Society at the University of Ruhuna, demonstrating engineering prowess and ingenuity in structural design and construction.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021 - Present</h3>
                                <p style={{ textAlign: 'justify' }} className="d_timeline-text">
                                	<span className="d_title">BSc (Hons) in Computer Engineering</span>
                                	<span className="d_company">University of Ruhuna</span>Currently pursuing a BSc (Hons) in Computer Engineering at the University of Ruhuna, Faculty of Engineering with a current OGPA of 3.21, combining theoretical knowledge with practical skills for a comprehensive understanding of computer engineering principles.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020 - 2021</h3>
                                <p style={{ textAlign: 'justify' }} className="d_timeline-text">
                                	<span className="d_title">DIE & DITEC</span>
                                	<span className="d_company">Esoft Metro Campus</span>Completed Assured Diploma in English (DIE) and Assured Diploma in Information Technology (DITEC) at Esoft Metro Campus, showcasing proficiency in both English language and literature as well as comprehensive skills in information technology.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2009 - 2019</h3>
                                <p style={{ textAlign: 'justify' }} className="d_timeline-text">
                                	<span className="d_title">Physical Science</span>
                                	<span className="d_company">Central Collage Kuliyapitiya</span>Achieved outstanding results in the Advanced Level examinations at Central College Kuliyapitiya in 2019, attaining 2A's and a B, demonstrating strong academic performance and a commitment to excellence in education.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;