import React from 'react';
import Typed from "react-typed";
import AOS from 'aos';
import CV_PDF from '../CV_SanduniManjula.pdf'; // Import the PDF file

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const Hero = () => {
    const downloadResume = () => {
        // Use the imported file as the source URL
        window.open(CV_PDF, '_blank');
    };

    return(
        <div className="v-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h6 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >Welcome to My Website
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big mb-0">Hi, I'm <span className="color">Sanduni Manjula</span></div>
                        <div className="h1_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <Typed
                                strings={["a Programmer. ", "a Developer." , "a Designer."]}
                                typeSpeed={60}
                                backSpeed={50}
                                loop
                              />
                        </div>
                        
                        <p style={{ textAlign: 'justify' }}  className="list_location"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                           A passionate website designer and full-stack developer from the esteemed University of Ruhuna, Faculty of Engineering.Specializing in seamless UI/UX, I thrive on evolving through challenges, eagerly showcasing my skills.
                        </p>
                       
                       
                        <button className="btn-main mt-3" onClick={downloadResume} style={{ cursor: 'pointer' }}>
                            My Resume
                        </button>
                      
                    </div>

                    <div className="col-md-6">
                        <img src="./img/misc/7.png" className="img-fluid" alt="imghero"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
